.slider-outer {
  --swiper-pagination-height: 2.8rem;
  width: 100%;
  position: relative;
}

.slider-container {
  width: 100%;
  padding-bottom: var(--swiper-pagination-height);
}

.slider-image-container {
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.aspect-ratio-container {
  position: relative;
  width: 100%;
  padding-top: calc(311 / 375 * 100%);
}

.swiper-pagination {
  bottom: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: var(--swiper-pagination-height);
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: #a0a0a0;
  opacity: 1;
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  background: #ffffff;
  width: 8px;
  height: 8px;
}

.swiper {
  position: relative;
}

.swiper-button-next,
.swiper-button-prev {
  @apply select-none text-white;
  position: absolute;
  min-width: 44px;
  min-height: 44px;
  margin: 0 !important;
  top: calc((100% - var(--swiper-pagination-height)) / 2) !important;
  transform: translateY(-50%) !important;
  opacity: 1 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.8);
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-disabled {
  opacity: 1 !important;
  pointer-events: auto !important;
}
